.folder {
  width: calc(16.66% - 20px);
  margin-right: 20px;
  @media screen and (max-width: 1500px) {
    width: calc(20% - 20px);
  }

  @media screen and (max-width: 1200px) {
    width: calc(25% - 20px);
  }

  @media screen and (max-width: 1000px) {
    width: calc(33.3% - 20px);
    height: auto;
  }
  
  @media screen and (max-width: 769px) {
    width: calc(50% - 20px);
    height: auto;
  }
}
.edit{
  opacity: 0;
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  padding: 3px;
}
.edit svg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.folder:hover{
  .edit{
    opacity: 1;
  }
  
}
.folder .title:hover{
  color: #70bbfd;
}
.title{
  cursor: pointer;
  transition: all .2s;
}
.title:hover{
  color: #70bbfd;
}
.wrapper{
  padding: 20px;
  color: black;
}
.input-file{
  width: 100%;
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 6px;
}
.uploadBtn{
  background-color: #49be25;
  border: none;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  margin: 10px 0px 5px 0px;
}
.uploadBtn:hover{
  background-color: #50cf29;
}
.uploadBtn:focus {outline:0;}
.uploadBtn:disabled{
  background-color: #f5f5f5;
  color:   #b8b8b8;
  border: 1px solid #b8b8b8;
  cursor: not-allowed;
}

.closeBtn{
  background-color: #1890ff;
  border: none;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  margin: 10px 0px 5px 0px;
}
.closeBtn:hover{
  background-color: #40a9ff;
}
.closeBtn:focus {
  outline:0;
}
.deleteBtn{
  padding: 3px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
}
.deleteBtn:hover{
  background-color: #ed5e68;
  color: white;
}
.editBtn{
  padding: 3px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
}
.editBtn:hover{
  background-color: #579bfa;
  color: white;
}
.teacher_img_input{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  cursor: pointer;
  border: 1px dashed #ddd;
  background: transparent;
}

.teacher_img_input:hover{
  border-color: darkcyan;
}
.announcement-container{
  margin: auto;
  @media screen and (min-width: 1200px) {
    max-width: 1150px;
  }
}
.wysiwyg-container{
  img{
    width: auto;
  }
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

// .tox .tox-notification--in { display: none !important }