.course_img_input{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  cursor: pointer;
  border: 1px dashed #ddd;
  background: transparent;
}

.course_img_input:hover{
  border-color: darkcyan;
}
