.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  position: fixed;
}
.content_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 40rem;
  max-height: 40rem;
  background-color: white;
  border-radius: 0.375rem;
  background-color: rgb(255, 237, 213);
  padding: 0.75rem 1.5rem;
}
.content_header{
  color: rgba(100, 116, 139);
}
.close_btn{
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem;
  background-color: transparent;
  border: none;
}
.content_body{
  padding: 0.75rem 0;
  color:rgb(71 85 105);
}
.content_title{
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(248 113 113);
}

.table_wrapper{
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(51, 65, 85);
}





@keyframes down {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.animate {
  animation: down .4s ease-out;
}